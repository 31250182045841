@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants';

.loginFormWrapper {
  margin-top: 2rem;
}

.loginButton {
  margin-top: 12px;
  width: 100%;
}

.separator {
  display: block;
  width: 100%;
  margin: 25px 0;
  text-align: center;
  color: $grey-extra-dark;
}

.welcomeImage {
  width: 100%;
  margin-bottom: 50px;
  border-radius: 0.5rem;
}

.backButton {
  margin-top: 12px;
  display: block;
  text-align: center;
}
