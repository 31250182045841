@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.75rem;
}

.textInput {
  max-width: 50%;
  width: 100%;
}
