@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

$medkitButtonPrimaryBackgroundColor: var(
  --medkit-button-inactive-color,
  $lightgray
);
$medkitButtonActiveColor: var(--medkit-button-active-color, $grey-hover);
$medkitTextBaseColor: var(--medkit-text-list-item-base-color, $grey-extra-dark);

.ArrowListItem {
  cursor: pointer;
  list-style: none;
  display: block;

  &:hover {
    background: $medkitButtonActiveColor;
  }

  &--noArrow {
    button {
      cursor: initial !important; /* stylelint-disable-line */
    }

    .ArrowListItem__decoration {
      display: none;
    }
  }

  &__decoration {
    fill: var(--icon-fill, $grey-600);
  }

  a {
    text-decoration: none;
    display: block;
    padding: 0.5rem 0;

    &:hover {
      text-decoration: none;
    }
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 1rem;
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  a,
  button {
    padding: 0.5rem 1rem;
    transition: background-color $transition-time $transition-ease;
    text-indent: 0;
  }

  &[disabled] {
    a,
    button {
      &:hover {
        background: inherit;
      }
    }
  }

  &--wide {
    a,
    button {
      padding: 15px 1rem;
    }
  }

  &--important {
    background-color: $negative-extra-light;
    border-radius: 4px;

    &:hover {
      background-color: $negative-extra-light;
    }

    .ArrowListItem__image svg,
    .ArrowListItem__decoration {
      fill: $negative-dark;
    }
  }

  &--option {
    background-color: $medkitButtonPrimaryBackgroundColor;
    border-radius: 4px;
  }
}

.ArrowListItem__image {
  margin-right: 15px;
  display: inline-flex;
  vertical-align: middle;

  svg {
    @include icon;
  }
}

.ArrowListItemCopy {
  display: inline-flex;
  width: auto;
  text-decoration: none;
  color: $medkitTextBaseColor;
  font-size: 1rem;
  vertical-align: middle;
  padding: 5px 20px 5px 0;

  @include tablet-mini {
    padding: 0;
  }

  &:hover {
    color: $medkitTextBaseColor;
  }
}

.ArrowListItemTip {
  display: inline-block;
  color: $grey-dark;
  margin: 0 40px 0 auto;
  font-size: 1rem;
  flex: 1;
}

.loading {
  display: flex;

  &--square {
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }

  &--text {
    margin-top: 5px;
  }
}
