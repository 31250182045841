@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.requestAppointment {
  &__contentSection {
    margin-bottom: 2rem;

    ul {
      @extend %ul-default-style;
    }
  }
}
