@import '/home/circleci/repo/web-app/src/overrides.scss';
.titleV1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
}

.subTextV1 {
  display: block;
  margin-top: 1rem;
}

.checkBoxContainerV1 {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.followUpQuestionWrapperV1 {
  padding-left: 1rem;
}

.followUpQuestionWrapperV1 > div > label {
  font-size: 15px;
}

.errorMessageV1 {
  display: flex;
  margin-top: 1rem;
  color: rgba(255, 0, 0, 0.7);
}
