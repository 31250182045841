@import '/home/circleci/repo/web-app/src/overrides.scss';
.wrapper {
  padding: $main-padding-h;
  border: 1px solid #1860f0;
  border-radius: $border-radius-large;
  background-color: #eaf2fb;
}

.header {
  color: #1860f0;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;

  span {
    color: #1860f0;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.icon {
  fill: #1860f0;
}
