@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  border: 1px solid #dbdbdb;
  padding: 16px;
  margin-top: 12px;
  color: #444;
  border-radius: 4px;
  min-height: 60px;
  height: fit-content;
  box-shadow: none;
  background-color: white;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.body {
  margin-left: 35px;
  font-size: 14px;
}

.label {
  display: flex;
}

.container:hover {
  cursor: pointer;
}

.container input {
  display: none;
}

.checked {
  border: 1px solid #000;
  box-shadow: 4px 2px 9px rgba(0, 0, 0, 0.13);
}

.radioMark {
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background-color: rgb(255, 255, 255);
  align-items: center;
  display: flex;
  margin-right: 15px;
}

.radioMark::after {
  content: '';
  display: none;
}

.container input:checked ~ .radioMark::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  align-self: center;
  margin: 0 auto;
  border-radius: 50%;
  background-color: $accentColor;
}

.extraLabel {
  color: #aaa;
}
