@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';
@import 'components/Form/Form.module';

// stylelint-disable
:export {
  grey75: $grey75;
}
// stylelint-enable

.addressPicker {
  &__input,
  &__mainButton {
    margin: 10px 0;
  }

  &__input {
    width: 130px;

    input {
      text-transform: uppercase;
    }
  }

  input:disabled {
    @extend %input-disabled;
  }

  &__address {
    color: $grey75;
    margin-top: 1rem;
  }

  &__changeLink {
    display: block;
    margin: 10px 0;
  }
}

.error {
  color: $error;
  display: block;
  padding-bottom: 12px;
  font-size: 14px;
}

%input-disabled {
  background-color: $grey-extra-light;
  font-weight: bold;
  border: none;
}
