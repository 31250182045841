@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  border: 0;
  padding: 0;
  margin: 0;
}

.radioButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.textAreaWrapper > div > label {
  font-size: 15px;
}
