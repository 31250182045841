@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.error {
  color: $color-error;
}

.textInput {
  line-height: 1rem;
  border: 1px solid rgba(211, 211, 211, 0.59);
  padding: 7px 16px 7px 16px;
  box-shadow: none;
  border-radius: 0.25rem;
  height: 50px;
  width: 100%;

  &[aria-invalid='true'] {
    border: 1px solid red;
  }
}
