@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.modalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 16px 0;
  width: 100%;
  height: 100%;
  // Dimensions dependent on mention-me content
  max-width: 891px;
  max-height: 597px;
  background: $white100;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iframeContainer {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.closeBtn {
  overflow: hidden;
  position: absolute;
  width: 30;
  height: 30;
  top: 12px;
  right: 6px;
  padding: 0;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  transition: all $transition-time $transition-ease;
  z-index: 10001;

  @include tablet {
    right: 12px;
  }

  &__icon {
    @include icon;
  }
}

.loadingContainer {
  position: absolute;
}
