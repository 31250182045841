@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants';

.badge {
  border-radius: 0 8px 0 8px;
  background-color: $accentColor;
  padding: 4px 8px;
  display: inline-block;
  visibility: visible;
}

.hiddenBadge {
  display: none;

  @media (min-width: $medium-breakpoint) {
    visibility: hidden;
    display: inline-block;
  }
}
