@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.wrapper {
  display: flex;
  margin-top: 34px;
}

.title {
  color: $label-color;
  display: block;
  font-size: 16px;
  font-weight: normal;
  line-height: $body-line-height;
  margin: 0;
  padding: 6px 0;
}

.switch {
  margin-left: 18px;
}

.subtitle {
  font-size: 14px;
  margin: 0;
  color: $grey-dark;
}

.content {
  flex: 1;
}
