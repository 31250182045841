@import '/home/circleci/repo/web-app/src/overrides.scss';
.searchInput {
  & > div {
    position: relative;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
  }

  input {
    padding-right: 40px;
  }

  :global(.web-app__Spinner__container) {
    position: absolute;
    top: 19px;
    right: 15px;
    height: auto;
  }
}
