@import '/home/circleci/repo/web-app/src/overrides.scss';
.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: block;
  line-height: 2.3rem;
}

.error {
  color: $color-error;
}
