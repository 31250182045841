@import '/home/circleci/repo/web-app/src/overrides.scss';
.wrapper {
  width: 100%;
  margin: 2rem 0;
}

.card {
  margin-top: 2rem;
  padding: $main-padding-h;
  background-color: $grey15;
}

.star {
  fill: $yellow100;
}

.semiBold {
  font-weight: 500;
}

.additionalData {
  color: $darkgray;
}

.marginBottom {
  margin-bottom: 1rem;
}
