@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants';

.productCardContainer {
  border-width: 1px;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.mobileViewOnly {
  @media (min-width: $medium-breakpoint) {
    display: none;
  }
}

.mobileViewHidden {
  display: none;

  @media (min-width: $medium-breakpoint) {
    display: block;
  }
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.iconButton {
  width: 24px;
  height: 24px;
}

.priceFigure {
  font-size: 14px;
}

.productInfoContainer {
  position: relative;
  display: flex;
}

.productName {
  font-size: 20px;
  font-weight: 600;
}
