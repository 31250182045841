@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.userMenu {
  width: 100%;
  padding: 0;
  box-shadow: $shadow;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;

  ul {
    margin: 0;

    button {
      cursor: pointer !important;
    }
  }
}
