@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.formLoginBanner {
  width: 100%;
  margin: 20px 0;
  background-color: rgba(0, 94, 184, 0.1);
  padding: 20px;
  border-radius: 4px;
  display: table;
  overflow: hidden;

  &__text {
    display: block;
    text-align: center;
    margin: 0 0 16px 0;

    @include tablet-mini {
      margin: 0 20px 0 0;
      display: table-cell;
      vertical-align: middle;
      text-align: left;
    }
  }

  button {
    width: 100%;

    @include tablet-mini {
      width: auto;
      float: right;
    }
  }
}
