@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

$zIndex: 99;

.navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 150px;
  margin: 0.125rem 0;

  &__item {
    display: inline-block;
    margin-right: 30px;
    position: relative;

    a {
      color: black;
      font-weight: 400;
      display: block;
      padding: 0.625rem 0; /* bigger hit area */
    }

    a > span {
      padding: 0;
    }
  }

  &__icon {
    @include icon;
    fill: black;
    margin-right: 12px;
    display: inline-block;
  }

  &__itemAlert {
    $size: 8px;
    background-color: $negative;
    border-radius: 50%;
    display: block;
    height: $size;
    width: $size;
    left: $size * 2;
    overflow: hidden;
    position: absolute;
    text-indent: -999rem;
    top: $size;
  }
}

@media (max-width: $medium-breakpoint) {
  .navigation {
    width: 100%;
    height: 0;
    max-height: none;
    float: none;
    position: fixed;
    z-index: $zIndex;
    top: 47px;
    left: 0;
    background-color: $white100;
    transition: opacity 500ms ease-in, height 500ms ease-in-out;
    overflow: hidden;
    overflow-y: scroll;
    transform: none;

    &.opened {
      opacity: 1;
      height: 100%;
      transition: opacity 450ms ease-out, height 500ms ease-in-out;

      .navigation__item {
        opacity: 1;
        transform: none;

        &.no-border {
          border: none;
        }

        &:nth-child(1) {
          transition-delay: 250ms, 250ms;
        }

        &:nth-child(2) {
          transition-delay: 300ms, 300ms;
        }

        &:nth-child(3) {
          transition-delay: 450ms, 450ms;
        }

        &:nth-child(4) {
          transition-delay: 500ms, 500ms;
        }

        &:nth-child(5) {
          transition-delay: 550ms, 550ms;
        }

        &:nth-child(6) {
          transition-delay: 600ms, 600ms;
        }

        &:nth-child(7) {
          transition-delay: 650ms, 650ms;
        }

        &:nth-child(8) {
          transition-delay: 700ms, 700ms;
        }

        &:nth-child(9) {
          transition-delay: 750ms, 750ms;
        }

        &:nth-child(10) {
          transition-delay: 800ms, 800ms;
        }

        &.item--cta {
          padding: 10px 0;
          border: none;
        }
      }
    }

    &__links {
      opacity: 0;
      transition: opacity 450ms ease-out;
      transition-delay: 250ms;
      width: 100%;
      padding: 0 20px;
      display: none;

      &.opened {
        margin-top: 0;
        opacity: 1;
        transition: opacity 450ms ease-out;
        display: block;
      }
    }

    &__item {
      display: block;
      margin: 0.625rem 0;
    }
  }
}
