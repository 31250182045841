@import '@babylon/design-tokens/all.scss';
$primary: black;
$accentColor: #3eeab0;
$accentActiveColor: #48efb3aa;
$color-error: #e2211e;
$color-divider: #e5e6e7;
$color-text-caption: #656668;
$color-text-heading: #000;
$font-family: Poppins, Helvetica, Arial, sans-serif;
$extra-large-breakpoint: 1200px;
