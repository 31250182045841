@import '/home/circleci/repo/web-app/src/overrides.scss';
.toggleSwitch {
  width: 74px;
  height: 22px;
  border: 1px solid #000;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  position: relative;
  overflow: hidden;
}

.slider {
  position: absolute;
  height: 100%;
  width: 50%;
  border: 2px solid white;
  border-radius: 5px;
  font-weight: 500;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  left: 0;
  z-index: 1;
}

.label {
  height: 100%;
  width: 50%;
  border: 2px solid white;
  border-radius: 10px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider.active {
  transform: translateX(100%);
}

.sliderLabel {
  position: relative;
  z-index: 2;
}
