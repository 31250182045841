@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 20;

  &--overlay {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
  }

  &--inline {
    display: flex;
    vertical-align: middle;
    position: static;
    height: auto;
    width: auto;
    align-content: center;

    svg {
      display: flex;
    }
  }

  &--stretch {
    position: static;
  }

  &--small {
    position: relative;
    z-index: 0;
  }

  &--transparent {
    background-color: transparent;
  }

  // visually hidden, but semantically relevant
  &__text {
    left: -999rem;
    position: absolute;
  }

  ~ main,
  ~ footer {
    display: none;
  }
}

/* Specific animation for IE which doesn't support SVG animation */
@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

.heart {
  animation: beat 1.2s ease-in-out infinite forwards;
  width: 50px;

  @include tablet {
    width: 80px;
  }
}

@keyframes beat {
  0%,
  100% {
    transform: scale3d(1, 1, 1);
  }

  15%,
  45% {
    transform: scale3d(1.1, 1.1, 1);
  }

  35% {
    transform: scale3d(1.05, 1.05, 1);
  }

  65% {
    transform: scale3d(1, 1, 1);
  }
}
