@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.avatar {
  $avatar-size-xxl: 6.25rem;
  // gap for xl
  $avatar-size-l: 3.75rem;
  $avatar-size: 3.125rem;
  // gap for s
  $avatar-size-xs: 1.875rem;

  $icon-size-l: 1.5rem;
  $icon-size: 1.25rem;

  height: $avatar-size;
  position: relative;
  width: $avatar-size;

  &__imgWrapper,
  &__default,
  &__text {
    border-radius: 50%;
    display: block;
  }

  &__img,
  &__default {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__imgWrapper,
  &__text {
    height: 100%;
    width: 100%;
  }

  &__imgWrapper {
    position: relative;
    overflow: hidden;
    background-size: cover;
  }

  &__img {
    display: block;
    max-height: 100%;
    min-height: $avatar-size;
    min-width: $avatar-size;
  }

  &__default {
    @include icon($secondary, 120%);
  }

  &__iconWrapper {
    bottom: -5px;
    height: $icon-size;
    position: absolute;
    right: -5px;
    width: $icon-size;

    svg {
      @include icon($primary, 100%, top);
      --icon-fill: #fff;
    }

    &--highlight {
      background: $primary;
      border-radius: 50%;
      padding: 0.125rem;

      svg {
        fill: #fff;
      }
    }
  }

  &__text {
    background-color: $grey-dark;
    color: #fff;
    font-size: 14px;
    line-height: $avatar-size;
    text-align: center;
    text-transform: uppercase;
  }

  // display variations
  &--frameless {
    .avatar__imgWrapper,
    .avatar__default,
    .avatar__text {
      border-radius: 0;
    }
  }

  // size variations
  &--xxl,
  &--l {
    .avatar__iconWrapper {
      height: $icon-size-l;
      width: $icon-size-l;

      &--highlight {
        padding: 0.25rem;
      }
    }
  }

  &--xxl {
    height: $avatar-size-xxl;
    width: $avatar-size-xxl;

    .avatar__img {
      min-height: $avatar-size-xxl;
      min-width: $avatar-size-xxl;
    }

    .avatar__text {
      line-height: $avatar-size-xxl;
    }
  }

  &--l {
    height: $avatar-size-l;
    width: $avatar-size-l;

    .avatar__img {
      min-height: $avatar-size-l;
      min-width: $avatar-size-l;
    }

    .avatar__text {
      line-height: $avatar-size-l;
    }
  }

  &--xs {
    height: $avatar-size-xs;
    width: $avatar-size-xs;

    .avatar__text {
      line-height: $avatar-size-xs;
    }

    .avatar__img {
      min-height: $avatar-size-xs;
      min-width: $avatar-size-xs;
    }
  }

  svg {
    fill: var(--medkit-avatar-default-avatar-fill, $primary);
  }
}
