@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants';

.productContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;

  @media (min-width: $medium-breakpoint) {
    flex-direction: row;
  }
}
