@import '/home/circleci/repo/web-app/src/overrides.scss';
.formContainer {
  font-family: Poppins, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
}

.form {
  width: 100%;
  animation: fadeIn 0.75s ease-in-out;
  transition: all 0.2s ease 0s;
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid rgba(211, 211, 211, 0.69);
}

.progressBar {
  display: flex;
  width: 100%;
  height: 5px;
  border-radius: 2px;
  margin-bottom: 2rem;
  background-color: rgba(208, 206, 206, 0.2);
  border: 1px solid rgba(211, 211, 211, 0.59);

  span {
    background-color: black;
    border-radius: 2px;
    height: 5px;
    transition: width 2s ease 1s;
  }
}

.callOut {
  border: 2px solid #3eeab0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.5rem;
  padding: 0.75rem;

  h6 {
    margin: 0;
  }

  & > .callOutBody {
    font-size: smaller;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
