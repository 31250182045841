@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.listItem {
  width: 100%;
  padding: $space--s 0;
  list-style: none;

  &:hover {
    background: $grey-hover;
  }

  a,
  button {
    padding: $space--s $space--m;
    transition: background-color $transition-time $transition-ease;
  }

  a {
    text-decoration: none;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }
}
