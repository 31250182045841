@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.Link {
  color: $primary;
  display: inline;
  text-decoration: none;
  -webkit-transition: color 0.3s ease-in-out;
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $primary-d10;
  }

  a {
    display: inline;
    text-decoration: none;
  }

  &.grey {
    color: $grey-extra-dark;
  }
}
