@import '/home/circleci/repo/web-app/src/overrides.scss';
.radio {
  visibility: hidden;
  position: absolute;
}

.label {
  border: 1px solid #c5d0de;
  padding: 17px;
  display: flex;
  border-radius: 6px;
  cursor: pointer;
  align-items: center;
  margin-bottom: 16px;
}

.radio:checked + .label {
  padding: 16px;
  border: 2px solid #2bbbbb;
}

.icon {
  display: flex;
  margin-right: 16px;
  height: 40px;
  width: 40px;
  background: #e9f8f8;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.icon svg {
  fill: #2bbbbb;
}

.title {
  font-size: 16px;
  margin-bottom: 4px;
}

.description {
  font-size: 14px;
  color: #637280;
}
