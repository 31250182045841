@import '/home/circleci/repo/web-app/src/overrides.scss';
.link {
  display: flex;
  align-items: center;
  margin-bottom: 26px;

  svg {
    margin-right: 10px;
  }
}
