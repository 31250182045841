@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  transform-origin: center;
  animation: spinner_zKoa 2s linear infinite;
}

.spinner circle {
  stroke-linecap: round;
  animation: spinner_YpZS 1.5s ease-in-out infinite;
}

@keyframes spinner_zKoa {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner_YpZS {
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }

  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }

  95%,
  100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
}
