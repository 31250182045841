@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

$max-width: 667px;
$spacing: 20px;

.main-section {
  max-width: $max-width;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-self: center;
}

.heading {
  font-size: 2em;
  line-height: 1.2em;
  margin: 0 0 $spacing;

  @include tablet-mini {
    font-size: 2.5em;
  }
}

.heading-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;

  p {
    margin: 0;
  }

  button {
    max-width: 195px;
    margin: 0;
  }
}

.description {
  margin-top: 0;
  white-space: pre-wrap;
}

.home-image {
  max-width: $max-width;
  width: 100%;
  margin-bottom: 1em;
}

.setup-confirmation-image {
  max-width: $max-width;
  width: 100%;
  height: 450px;
  object-fit: cover;
  margin-bottom: 1em;
}

.footnote {
  margin-top: 0;
  font-size: 12px;
  color: #1f2937;
}

.cards {
  margin-bottom: $spacing;
}

.requirements {
  margin: 0.3em $spacing;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-weight: 600;

    svg {
      margin-right: 8px;
    }
  }
}

.check-in-startup-confirmation-image {
  max-width: $max-width;
  width: 100%;
  object-fit: contain;
  margin-bottom: 1em;
}

.check-in-startup-cards {
  margin-bottom: $spacing;

  div img {
    width: 60px;
    height: 60px;
  }
}

.cta {
  margin-bottom: $spacing;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.weightLossStepper {
  /* Three steps fit fine on mobile so always show step labels */

  :global(.medkit__Step__Step) {
    &:first-child::before {
      display: none;
    }

    &::before {
      display: block;
    }
  }

  :global(.medkit__Step__Step__label) {
    display: block;
  }

  /* Make stepper black while we're waiting for rebranding */

  :global(.medkit__Step__Step_highlight .medkit__Step__Step__circle) {
    background-color: #000;
  }
}

.product-box {
  font-size: 16px;
  display: flex;
  flex-direction: column; /* Change to column on mobile */
  align-items: center; /* Center items horizontally on mobile */
  width: 100%;
  margin: 0 auto; /* Center the box horizontally */
  padding: 10px 10px 10px 15px;
}

.info {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Change to column on mobile */
  align-items: center; /* Center text horizontally on mobile */
  text-align: center; /* Center text vertically on mobile */
  margin-left: 10px; /* Add some space between the thumbnail and info */
}

.title {
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  color: rgb(65, 65, 65);
  margin-bottom: 10px; /* Add margin to separate subtitle and price */
}

.price {
  font-weight: bold;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
}

.dashboard-header-item.green {
  color: #35b587;
}

.dashboard-header-item.red {
  color: #fc4a4a;
}

.dashboard-chart {
  margin: 30px 0;
}

.dashboard-check-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-check-in:not(:last-of-type) {
  border-bottom: 1px solid $grey-light;
}

.dashboard-check-in-date {
  width: 50%;
}

.dashboard-check-in-weight {
  text-align: right;
  width: 30%;
}

.dashboard-check-in-weight-loss-container {
  min-width: 100px;
  text-align: right;
}

.dashboard-check-in-weight-loss {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
}

.dashboard-check-in-weight-loss.red {
  border: 2px solid #fc4a4a;
  background-color: #fc4a4a;
  color: white;
}

.dashboard-check-in-weight-loss.green {
  border: 2px solid #35b587;
  background-color: #35b587;
  color: white;
}

.dashboard-manage-subscription-button-div {
  width: 70%;
  align-self: center;
}

.small-text {
  color: $grey-600;
  font-size: 11px;
  margin-bottom: 11px;
}

@media (min-width: 600px) {
  .product-box {
    flex-direction: row; /* Reset to row layout on larger screens */
    align-items: flex-start; /* Align items to the start horizontally on larger screens */
  }

  .info {
    margin-left: 10px; /* Reset margin for larger screens if needed */
    text-align: start; /* Reset text alignment for larger screens if needed */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .weightLossStepper {
    :global(.medkit__Step__Step) {
      font-size: 12px;
    }
  }
}

.check-in-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.discount-code-alert {
  margin-top: 10px;
  background-color: $accentColor;
}
