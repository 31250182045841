@import '/home/circleci/repo/web-app/src/overrides.scss';
.section {
  margin: 0 0 calc(2 * $space--xl);
}

.header {
  margin-bottom: $space--l;
}

.link {
  display: block;
  text-decoration: none;
}
