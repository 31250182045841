@import '/home/circleci/repo/web-app/src/overrides.scss';
/* stylelint-disable declaration-no-important */

@import 'components/constants.scss';

.modalOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  z-index: 10001;
  cursor: pointer;

  &.avoidClose {
    cursor: initial;
  }
}

.modal {
  width: 100%;
  height: 100%;
  border-radius: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $sterile-white;
  padding: 20px;
  overflow-y: auto;
  z-index: 10001;

  @include tablet {
    max-height: 90%;
  }

  @include tablet-mini {
    max-width: 600px;
    max-height: 90%;
    border-radius: $border-radius;
    padding: 40px;
    height: auto;
  }

  h1 {
    margin-top: 0;
  }

  h3 {
    line-height: 2.4rem;
  }

  h4 {
    display: block;
    margin: 20px 0;
    line-height: 1.8rem;
  }

  a {
    text-decoration: none;
  }

  .modalSubtitle {
    color: $grey-extra-dark;
    letter-spacing: -0.1px;
  }

  :global .babylon-component-library__Radiogroup__radio__label::after {
    top: 3px;
  }
}

.centered {
  text-align: center;
}

.Message:last-of-type {
  margin-bottom: 20px;
  display: block;
}

.closeBtn,
.backBtn {
  overflow: hidden;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 12px;
  padding: 0;
  appearance: none;
  /* stylelint-disable */
  -webkit-appearance: none;
  /* stylelint-enable */
  background: none;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 10002;

  @include tablet-mini {
    top: 20px;
  }

  &__icon {
    @include icon;
  }
}

.closeBtn {
  right: 10px;

  @include tablet-mini {
    right: 15px;
  }
}

.backBtn {
  left: 12px;

  @include tablet-mini {
    left: 20px;
  }
}

.PasswordInput,
.SubmitBtn {
  position: relative;
  margin: 15px 0;
  display: block;
}

.Specialists {
  margin: 25px 0 10px;
}

.Specialist {
  display: block;
  background-color: transparent;
}

.Specialism {
  color: #333; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
  display: block;
  margin: 15px 0;
  font-weight: 600;
}

.dateOfBirthSelect {
  font-size: 0.9rem;
  padding: 0 5px;
  position: relative;
  text-align: left;
}

.buttonGroup {
  width: 232px;
  margin: 30px auto 5px;
}

.orphanText {
  display: block;
  margin-top: 25px;
  text-align: center;
}

.callModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.callActions {
  margin-top: 48px;

  button {
    overflow: hidden;
    width: 100%;
    margin-bottom: 15px;
    margin-left: 0 !important;
    margin-right: 0;

    @include tablet-mini {
      width: auto;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  &__icon {
    @include icon(#fff);
  }
}

.cancelModal {
  .cancelTitle {
    margin-bottom: 38px;
  }

  label {
    font-size: 14px;
  }

  .textAreaEnabled,
  .textAreaDisabled {
    margin-top: 38px;
  }

  .textAreaDisabled {
    textarea {
      background-color: $grey-light;
    }
  }

  .sendCancel {
    margin-top: 32px;
  }
}

.confirmation {
  &__text {
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.2;
    text-align: center;

    @include tablet-mini {
      margin-bottom: 1.5rem;
      margin-top: 0;
    }
  }

  &__actions {
    // importants override aggressive component library styles
    position: absolute;
    bottom: 0;
    padding: 2rem 1rem;
    width: 100%;
    left: 0;

    @include tablet-mini {
      position: static;
      padding: 0;
      display: flex;
      justify-content: center;

      button {
        width: auto !important;
      }

      button:last-child {
        margin: 0 0 0 1rem !important;
      }
    }

    button {
      width: 100% !important;
    }
  }
}

.fullWithBtn {
  width: 100% !important;
}

.promotionImage {
  max-width: 100%;
  max-height: 200px;
}

.privacyModal {
  @extend .modal; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */

  display: flex;
  overflow: hidden;
  flex-direction: column;

  @include tablet-mini {
    height: 100%;
  }

  .scrollable {
    overflow: auto;

    &--actionButton {
      margin-bottom: 74px;
    }
  }

  .infoButton {
    background: transparent;
    border: 0;
    display: block;
    margin-bottom: 20px;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .fixedButtons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: $sterile-white;

    @include tablet-mini() {
      padding: 10px 20px 20px;
    }

    &.separator {
      border-top: 1px solid $grey-light;
    }
  }
}

.login {
  padding: 0;
}

.tickIcon {
  width: 80px;
  height: 80px;
  position: relative;

  &__circle {
    width: inherit;
    height: inherit;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1;
  }

  svg {
    @extend %full-center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.storeBadge {
  margin: 0 10px;
}

:global .gdpr h1 {
  line-height: 1.4;
}

.error {
  color: $red100;
}
