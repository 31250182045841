@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  padding-left: 1rem;
}

.container > div > label {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}
