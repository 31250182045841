@import '/home/circleci/repo/web-app/src/overrides.scss';
.containerV1 {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.containerV1 label {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
}

.textAreaV1 {
  line-height: 1rem;
  resize: vertical;
  border: 1px solid rgba(211, 211, 211, 0.59);
  padding: 7px 16px 7px 16px;
  box-shadow: none;
  border-radius: 0.25rem;
  max-height: 40em;
  min-height: 8em;
}

.errorV1 {
  border: 1px solid red;
}

.errorMessageV1 {
  margin-top: 1rem;
  color: rgba(255, 0, 0, 0.7);
}
