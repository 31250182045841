@import '../global-constants';

$stepper-bg-highlight-color: var(
  --medkit-stepper-bg-highlight-color,
  var(--medkit-primary, $primary)
);
$stepper-text-highlight-color: var(
  --medkit-stepper-text-highlight-color,
  $white100
);
$stepper-text-active-color: var(--medkit-steper-active-color, $grey90);
$stepper-base-color: var(--medkit-stepper-base, $shuttle-gray);
$stepper-icon-fill-color: var(--medkit-stepper-icon-fill-color, $white100);
