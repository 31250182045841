@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants';

.datePicker {
  display: flex;
  flex-direction: row;

  label {
    color: $label-color;
  }
}

.error {
  select {
    border-color: red;
  }
}

.pickerItem {
  flex: 1 1 auto;
  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }
}
