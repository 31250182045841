@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.list {
  margin: 20px 0;
  padding: 0;
  width: 100%;

  &:empty {
    margin: 0;
  }
}
