@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.container {
  @extend %full-center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.25rem;
  text-align: center;
  width: 100%;

  &--inline {
    padding: 0;
    position: static;
    transform: none;
  }
}

.icon {
  margin-bottom: 1rem;
}

.title {
  color: $grey100;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 0 0 0.625rem;
}

.detail {
  color: $grey100;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  margin: 0 0 1rem;
  white-space: pre-wrap;
  margin-bottom: 2rem;

  a {
    @extend %link-style;
    padding: 0;
  }
}
