@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  font-size: 1.1rem;
  font-weight: 400;
  white-space: pre-line;

  ul {
    margin-left: 1.5rem;
  }

  ul > li {
    padding-top: 8px;
    list-style-type: disc;
  }
}

.spacer {
  margin-bottom: 1rem;
}

.footerSpacer {
  margin-top: 2rem;
}

.text {
  display: block;
  margin-top: 0.25rem;
}

.subText {
  margin: 1em 0;
  font-size: 12px;
}
