@import '/home/circleci/repo/web-app/src/overrides.scss';
.marketingConsentInput {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.emailInput {
  display: block;
}

.privacyPolicy {
  font-size: 0.75rem;
  color: #444;
}
