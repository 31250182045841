@import '/home/circleci/repo/web-app/src/overrides.scss';
.containerV1 {
  border: 0;
  padding: 0;
  margin: 0;

  legend {
    font-size: 24px;
    font-weight: 600;
  }
}

.radioButtonContainerV1 {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.textAreaWrapperV1 > div > label {
  font-size: 15px;
}

.errorMessageV1 {
  display: flex;
  margin-top: 1rem;
  color: rgba(255, 0, 0, 0.7);
}
