@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: $space--xl;
  margin-top: $space--xl;
}

.bodyLine {
  margin-bottom: $space--m;
}

.headerHorizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space--xl;
}

.headerVertical {
  display: flex;
  flex-direction: column;
  margin-bottom: $space--xl;
}

.title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: $space--xs;
}

.measurementContainer {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  gap: $space--l;
}

.textInput {
  max-width: 50%;
  width: 100%;
}

.buttonWrapper > button {
  font-size: 0.7rem;
  max-width: 10rem;
  float: right;
}

.disclaimer {
  color: $slategrey;
}
