@import '/home/circleci/repo/web-app/src/overrides.scss';
.progButton {
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  padding: 8px 20px;
  width: 100%;
  margin-bottom: 12px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 0.5rem;
}

.progButton:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.secondary {
  color: black;
  background: white;
}

.tertiary {
  color: black;
  background: white;
  border: none;
  text-decoration: underline;
}

.tertiary:hover {
  background-color: white;
  text-decoration: none;
}

.progButton:disabled {
  cursor: default;
}

.secondary:hover {
  background-color: rgba(211, 211, 211, 0.4);
}

.progButton_brand {
  background-color: black;
  color: white;
}

.progButton_neutral {
  background-color: white;
  color: black;
}

.progButton_neutral:hover {
  background-color: rgba(211, 211, 211, 0.4);
  color: black;
}

.green {
  color: black;
  background: rgb(73, 239, 179);
  border: none;
}

.green:hover {
  background: rgb(73, 239, 179);
  opacity: 0.7;
}

.shemed {
  color: white;
  background: #b4416c;
  border: none;
}

.shemed:hover {
  background: #b4416c;
  opacity: 0.7;
}
