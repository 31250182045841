@import '/home/circleci/repo/web-app/src/overrides.scss';
.containerV1 {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.titleV1 {
  font-size: 23px;
  font-weight: bold;
  line-height: 2rem;
}

.subTextV1 {
  font-size: 18px;
  text-align: justify;
  font-weight: 500;
}
