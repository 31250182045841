@import '/home/circleci/repo/web-app/src/overrides.scss';
.card {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;

  &:last-of-type {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }

  h6 {
    margin: 0 0 5px;
  }

  p {
    margin: 0;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
}
