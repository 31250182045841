@import '/home/circleci/repo/web-app/src/overrides.scss';
.titleV1 {
  font-size: 23px;
  font-weight: bold;
  line-height: 2rem;
}

.subTextV1 {
  font-size: 20px;
  text-align: justify;
  font-weight: 500;
}
