@import '/home/circleci/repo/web-app/src/overrides.scss';
.section {
  margin: 0 0 calc(2 * $space--xl);
  border-radius: 14px;
  box-shadow: rgb(215, 218, 220) 0 2px 12px 0;
}

.header,
.body,
.bodyLoading {
  padding: $space--l;

  @include tablet-mini {
    padding: $space--xl;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: 40px;
  background-color: $grey10;
  background-image: url('../../../assets/images/waves.svg');
  background-size: 300% 500%;
  background-repeat: no-repeat;
  animation: animatedBackground 20s linear infinite alternate;

  h2:not(:empty) {
    margin-top: $space--s;
  }

  .avatar {
    text-decoration: none;
  }
}

.body {
  .ctas:not(:empty) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space--m;

    button {
      flex: 1 1 auto;
    }
  }

  .membership,
  .bookNow {
    font-weight: 600;
  }

  .bookNow {
    margin-bottom: $space--m;
  }
}

.bodyLoading {
  .firstRow {
    display: flex;
    gap: $space--l;
  }

  .item {
    height: 30px;
    width: 100%;

    @include shimmerAnimation;
  }

  .item:not(:last-child) {
    margin-bottom: $space--l;
  }
}

@keyframes animatedBackground {
  from {
    background-position: 0% 50%;
  }

  to {
    background-position: 100% 50%;
  }
}
