@import '/home/circleci/repo/web-app/src/overrides.scss';
.containerV1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.75rem;
}

.titleV1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
}

.textInputV1 {
  max-width: 50%;
  width: 100%;
}
