@import '/home/circleci/repo/web-app/src/overrides.scss';
.fullBleedSection {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    left: -100vw;
    width: 200vw;
    height: 100%;
    background-color: $grey-hover;
    z-index: -1;
  }
}

.wrapper {
  width: 100%;
  margin: 2rem 0;
}

.text {
  font-size: 1.5rem;
  font-weight: 500;
}

.marginBottom {
  margin-bottom: 2rem;
}
