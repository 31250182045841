@import '/home/circleci/repo/web-app/src/overrides.scss';
.image {
  width: 100px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 4px $grey25;
}

.image:not(:last-child) {
  margin-bottom: 18px;
}

.name {
  font-weight: 500;
}

.title {
  color: $slategrey;
  font-size: 14px;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0;
}
