@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.title {
  font-size: 24px;
}

.content {
  text-align: center;
  max-width: 500px;
  place-self: center;
  margin: 20px;
}
