@font-face {
  font-family: 'Poppins';
  src: url('./Poppins-Bold.ttf') format('ttf'),
    url('./Poppins-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Poppins-SemiBold.ttf') format('ttf'),
    url('./Poppins-SemiBold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Poppins-Medium.ttf') format('ttf'),
    url('./Poppins-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Poppins-Regular.ttf') format('ttf'),
    url('./Poppins-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Poppins-Light.ttf') format('ttf'),
    url('./Poppins-Light.woff2') format('woff2');
  font-weight: 300;
}
