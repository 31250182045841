@import '/home/circleci/repo/web-app/src/overrides.scss';
$max-width: 640px;
$spacing: 20px;

.check-in-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.card-image {
  width: 100%;
  max-width: $max-width;
  margin-bottom: 1em;
}

.submit-error {
  color: red;
  margin-bottom: 1em;
  font-size: small;
}

.injection-layout {
  margin-top: 20px;
}

.weight-entry-submit-button {
  margin-top: 20px;
}

.weight-entry-change-unit-button {
  margin-top: 10px;
}

.weight-entry-next-step-button {
  margin-top: 10px;
}
