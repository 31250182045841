@import 'components/constants.scss';

.formRow {
  flex-direction: column;

  &.flexRow {
    display: flex;
  }

  input {
    &:required {
      box-shadow: none;
    }
  }

  @include form {
    flex-direction: row;
  }
}

.formItem {
  margin-bottom: 20px;

  @include form {
    flex: 1 1;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &.narrow {
      flex: 0.5 1;
      min-width: 140px;
    }
  }
}

.formButtonsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;

  button {
    width: auto;
    margin: 0;
  }
}

.formError {
  display: block;
  font-size: 0.8rem;
  color: $error;
  margin-bottom: 15px;
}

.formLegend {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #000;
}

.formSubtitle {
  display: block;
  margin: 2px 0 10px;
}

.formTip {
  display: block;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.75rem;
}

.partition {
  margin: 32px 0 23px 0;
  border: 1px solid $grey;
  border-bottom: none;
}
