@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';
@import 'components/Address/Address.module.scss';

.addressPicker__modal {
  min-height: 90vw;

  &__statesBody {
    margin-top: 40px;
    text-align: center;
    min-height: 169px;
  }

  &__form {
    position: relative;
  }

  &__clearBtn {
    position: absolute;
    right: 15px;
    bottom: 10px;
    cursor: pointer;
  }

  &__postcode {
    display: block;
    border-radius: 8px;
    padding: 1rem;
    height: auto;
    width: 100%;
    background-color: $grey-extra-light;
  }

  &__heading {
    margin-top: 0;
  }

  &__address-dark {
    color: $grey-extra-dark;
  }

  &__address-light {
    color: $grey75;
    font-size: 0.875rem;
  }

  &__input {
    input {
      text-transform: uppercase;
    }

    input:disabled {
      @extend %input-disabled;
    }
  }

  &__btnWrapper {
    margin-top: 40px;
  }
}
