@import '/home/circleci/repo/web-app/src/overrides.scss';
$max-width: 640px;
$spacing: 20px;

.main-section {
  max-width: $max-width;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-self: center;
}

.heading {
  font-size: 2em;
  line-height: 1.2em;
  margin: 0 0 $spacing;

  @include tablet-mini {
    font-size: 2.5em;
  }
}

.text {
  white-space: pre-line;
  display: block;
  margin-top: 1rem;
}

.description {
  margin-top: 0;
  white-space: pre-wrap;
}

.home-image {
  max-width: $max-width;
  width: 100%;
  margin-bottom: 1em;
}

.setup-confirmation-image {
  max-width: $max-width;
  width: 100%;
  height: 450px;
  object-fit: cover;
  margin-bottom: 1em;
}

.footnote {
  margin-top: 0;
  font-size: 12px;
  color: #1f2937;
}

.cards {
  margin-bottom: $spacing;
}

.requirements {
  margin: 0.3em $spacing $spacing;

  li {
    list-style-type: disc;
    list-style-position: inside;
  }
}

.check-in-startup-confirmation-image {
  max-width: $max-width;
  width: 100%;
  object-fit: contain;
  margin-bottom: 1em;
}

.check-in-startup-cards {
  margin-bottom: $spacing;

  div img {
    width: 60px;
    height: 60px;
  }
}

.apps-info {
  margin-bottom: $spacing;
}

.cta {
  margin-bottom: $spacing;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.stepper {
  /* Three steps fit fine on mobile so always show step labels */

  :global(.medkit__Step__Step) {
    &:first-child::before {
      display: none;
    }

    &::before {
      display: block;
    }
  }

  :global(.medkit__Step__Step__label) {
    display: block;
  }

  /* Make stepper black while we're waiting for rebranding */

  :global(.medkit__Step__Step_highlight .medkit__Step__Step__circle) {
    background-color: #000;
  }
}

.product-box {
  display: flex;
  flex-direction: column; /* Change to column on mobile */
  align-items: center; /* Center items horizontally on mobile */
  width: 100%;
  margin: 0 auto; /* Center the box horizontally */
  cursor: pointer;
  padding: 10px 10px 10px 15px;
  border: 1px solid $grey-light;
  border-radius: 10px;
  border-color: $accentColor;
}

.thumbnail {
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  margin-bottom: 10px; /* Add margin between image and info on mobile */
}

.thumbnail img {
  max-width: 150px;
  max-height: 100%;
}

.info {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Change to column on mobile */
  align-items: center; /* Center text horizontally on mobile */
  text-align: center; /* Center text vertically on mobile */
  margin-left: 10px; /* Add some space between the thumbnail and info */
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 16px;
  color: rgb(65, 65, 65);
  margin-bottom: 10px; /* Add margin to separate subtitle and price */
  white-space: pre-wrap;
}

.price {
  font-size: 16px;
  font-weight: bold;
}

@media (min-width: 600px) {
  .product-box {
    flex-direction: row; /* Reset to row layout on larger screens */
    align-items: flex-start; /* Align items to the start horizontally on larger screens */
  }

  .thumbnail {
    margin-bottom: 0; /* Remove margin on larger screens */
  }

  .info {
    margin-left: 10px; /* Reset margin for larger screens if needed */
    text-align: start; /* Reset text alignment for larger screens if needed */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.small-text {
  color: $grey-600;
  font-size: 11px;
}
