@import '/home/circleci/repo/web-app/src/overrides.scss';
.containerV1 {
  display: flex;
  border: 1px solid #dbdbdb;
  padding: 7px 16px;
  margin-top: 12px;
  color: #444;
  border-radius: 4px;
  min-height: 60px;
  height: min-content;
  box-shadow: none;
  background-color: white;
  align-items: center;
  vertical-align: middle;
  gap: 0.75rem;
}

.titleV1 {
  line-height: 1.5;
  font-size: 16px;
  white-space: break-spaces;
  text-align: left;
  word-break: break-word;
}

.checkedV1 {
  border: 1px solid #000;
  box-shadow: 4px 2px 9px rgba(0, 0, 0, 0.13);
}

.errorV1 {
  border: 2px solid red;
}

.containerV1 input:checked ~ .checkmarkV1 {
  display: inherit;
  background-color: #3eeab0;
}

.containerV1 input:checked ~ .checkmarkV1::after {
  content: '';
  display: inherit;
  border-color: black;
}

.containerV1 .checkmarkV1::after {
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: 2px;
  margin-left: 8px;
}

.containerV1:hover {
  border: 1px solid rgba(150, 150, 150, 0.62);
  cursor: pointer;
}

.containerV1 input {
  display: none;
}

.checkmarkV1 {
  display: block;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  padding: 0;
  border: 1px solid rgb(204, 204, 204);
  align-self: center;
}
