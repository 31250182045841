@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

// TODO refactor component
/* stylelint-disable */
.callContainer {
  &.noTouch {
    pointer-events: none;
  }

  .consultantVideo {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // Overwite the opentok inline style to make video fully responsive
    min-height: 100% !important;
    width: 100% !important;
    z-index: 20;
  }

  .patientVideo {
    background: #333;
    width: rem-calc(160);
    height: rem-calc(160);
    position: fixed;
    bottom: rem-calc(12);
    right: rem-calc(12);
    z-index: 21;

    @include less-than-tablet {
      bottom: rem-calc(110);
      // Overwite the opentok inline style to match our design for better UX
      width: rem-calc(130) !important;
      height: rem-calc(130) !important;
    }
  }

  .videoControls {
    width: 100%;
    text-align: center;
    position: fixed;
    z-index: 22;
    bottom: rem-calc(28);
    left: 50%;
    transform: translateX(-50%);
  }

  .hangUp {
    background-color: $negative;
  }

  .controlButton {
    background-color: $grey100;
    margin: 0 rem-calc(15);

    &:hover {
      opacity: 0.7;
    }

    &__text {
      @include visuallyHidden;
    }
  }

  .hangUp,
  .controlButton {
    border: none;
    width: rem-calc(68);
    height: rem-calc(68);
    border-radius: 50%;
    cursor: pointer;
  }
}
/* stylelint-enable */

.alert {
  margin-bottom: 1rem;
}

.caller {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include less-than-tablet {
    margin-top: 46px;
  }

  &__imageWrapper {
    width: 4.75rem;
    height: 4.75rem;
    padding: 0.5rem;
    overflow: hidden;
    border-radius: 50%;
    border: solid rem-calc(3.2) $primary;
    box-sizing: content-box;
  }

  &__image {
    margin: 0.5rem;
  }

  &__title {
    margin-top: rem-calc(14);
    font-size: rem-calc(28);
    line-height: rem-calc(38);
  }

  &__a11y {
    @include visuallyHidden;
  }

  &__actions {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    margin: rem-calc(20) 0;

    @include less-than-tablet {
      display: block;
    }
  }

  &__action {
    margin: 0 rem-calc(20);

    @include less-than-tablet {
      width: 100%;
      margin: rem-calc(10) 0;
    }
  }
}
