@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.footer {
  position: relative;
  font-size: 1rem;
  padding: 0 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid $grey-light;
  text-align: center;
}

.copyright {
  margin: 16px 0;
}

.footerNavigation {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include desktop {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.footerNavigationItem {
  white-space: nowrap;
  margin-bottom: 8px;
  display: block;

  @include desktop {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
}
