@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.header {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.title {
  flex-grow: 1;
}
