@import '/home/circleci/repo/web-app/src/overrides.scss';
.gpInfoForm {
  input {
    margin-bottom: 20px;
  }

  .error {
    color: red;
  }

  .footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;

    button {
      margin: 0;
    }
  }

  .gpInfoDisclaimer {
    margin-top: 50px;

    p {
      margin-top: 0;
    }
  }
}
