@import '/home/circleci/repo/web-app/src/overrides.scss';
.containerV1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 3rem;
}

.headerHorizontalV1 {
  display: flex;
  justify-content: space-between;
}

.headerVerticalV1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.titleV1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  line-height: 2rem;
}

.titleContainerV1 {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.measurementContainerV1 {
  display: flex;
  flex-direction: column;
}

.inputContainerV1 {
  display: flex;
  gap: 1rem;
}

.textInputV1 {
  max-width: 50%;
  width: 100%;
}

.buttonWrapperV1 > button {
  font-size: 11px;
  max-width: 10rem;
  float: right;
}

.dividerV1 {
  border-top: 1px solid rgba(211, 211, 211, 0.3);
}

.bmiTitleV1 {
  font-size: 24px;
  font-weight: 500;
}

.bmiContainerV1 {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 5px;
}

.bmiV1 {
  display: flex;
  align-items: center;
  border: 1px solid rgba(211, 211, 211, 0.59);
  padding: 7px 16px 7px 16px;
  box-shadow: none;
  background-color: rgba(211, 211, 211, 0.2);
  border-radius: 0.25rem;
  width: 10rem;
  height: 50px;
}

.errorMessageV1 {
  display: flex;
  margin-top: 1rem;
  color: rgba(255, 0, 0, 0.7);
}
