@import '/home/circleci/repo/web-app/src/overrides.scss';
$backgroundColor: #000;
$textColor: #fff;

.tooltipWrapper {
  margin-left: 8px;
  display: inline-block;
}

.icon {
  cursor: pointer;
}

.tooltip {
  width: 80vw;
  min-width: 150px;
  max-width: 80vw;
  position: absolute;
  font-weight: 400;
  background-color: $backgroundColor;
  color: $textColor;
  padding: 12px;
  border-radius: 8px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s;
  visibility: hidden;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 768px) {
    max-width: 350px;
  }

  &.top {
    bottom: 100%;
    margin-bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottom {
    top: 100%;
    margin-top: 10px;
  }
}
.tooltipWrapper:active .tooltip,
.tooltipWrapper:hover .tooltip,
.tooltipWrapper:focus-within .tooltip,
.tooltipWrapper:focus .tooltip {
  opacity: 1;
  visibility: visible;
}
