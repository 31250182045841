@import '/home/circleci/repo/web-app/src/overrides.scss';
.searchResult {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 25px;
  cursor: pointer;
  border: none;
  background: transparent;

  p {
    margin: 0;
    text-decoration: underline;
  }

  span {
    font-size: 20px;
  }
}

.searchResult:hover {
  background-color: #f5f5f5;

  p {
    text-decoration: none;
  }
}
