@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.noContent {
  position: relative;
  margin: 0 auto;
  max-width: 500px;

  &__iconWrapper {
    margin: 0 auto 2rem;
    width: 5rem;
    height: 5rem;
    background-color: #e9fbfb;
    border-radius: 50%;
    position: relative;
  }

  &__icon {
    @include icon(#23cccc, 2.5rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__detail {
    text-align: center;
  }

  &__title,
  &__subTitle {
    display: block;
    margin: 0 0 1rem;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 500;
    color: $grey-extra-dark;
  }

  &__subTitle {
    font-size: 1.5rem;
    color: $grey-dark;
  }
}
