@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';
@import 'components/Appointments/constants.scss';

.errorAlert {
  margin-top: 1rem;
  margin-bottom: 0.8rem;
}

.errorTitle {
  margin-bottom: 0.2rem;
}
