@import '/home/circleci/repo/web-app/src/overrides.scss';
/* stylelint-disable */
@import './components/constants.scss';

$medkitTitleTextBaseColor: var(
  --medkit-title-text-base-color,
  $grey-extra-dark
);

:global {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    outline: none;
  }

  html,
  body {
    position: relative;
    margin: 0;
    padding: 0;
  }

  html {
    height: 100%;
    font-size: 100%;

    @include tablet-mini {
      font-size: 100%;
    }
  }

  body {
    font-family: $font-family;
    font-weight: normal;
    line-height: $body-line-height;
    min-width: $min-width;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  body,
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  body.no-scroll {
    overflow: hidden;
    /* Safari mobile and IE needs addictional CSS for the body oveflow*/
    height: 100%;
    width: 100%;
    position: fixed;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $medkitTitleTextBaseColor;
    display: block;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    margin: 1rem 0;
  }

  h1,
  .h1 {
    font-size: 3.5rem;
    line-height: 3.5rem;
    margin: 2rem 0;
  }

  h2,
  .h2 {
    font-size: 2rem;
    line-height: 2.5rem;

    @include tablet-mini {
      font-size: 2.5rem;
    }
  }

  h3,
  .h3 {
    font-size: 1.777rem;
    line-height: 2.2rem;
  }

  h4,
  .h4 {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  h5,
  .h5 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.3rem;
  }

  button {
    font-weight: 400;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  img {
    vertical-align: middle;
  }

  a {
    text-decoration: none;
  }

  p {
    line-height: $body-line-height;
  }

  // TODO: convert all list to medkit and remove this CSS with related helpers
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  // END TODO

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  button {
    &.link {
      color: $turquoise100;
      cursor: pointer;
      display: inline-block;
      padding: 0.5rem 0;
      margin: 0.1rem 0;
      text-decoration: none;
      -webkit-transition: color 0.3s ease-in-out;
      position: relative;
      background-color: transparent;
      border: none;
      font-size: 1rem;

      :hover {
        color: $primary-d10;
      }
    }
  }

  *:focus {
    outline: 2px solid $blue100;
    outline-offset: 2px;
  }

  .hide-on-desktop {
    @include tablet {
      display: none !important;
    }
  }

  .Table {
    width: 100%;
    border-collapse: collapse;
    border: none;

    th {
      font-weight: bold;
      background: transparent;
    }

    td:last-child,
    th:last-child {
      width: 50px;
      padding-right: 15px;
    }

    td:first-child,
    th:first-child {
      padding-left: 15px;
    }

    tbody tr:nth-child(odd) {
      background-color: $grey-hover;
    }

    td,
    th {
      padding: 15px 0;
      text-align: left;
    }
  }

  /* Time picker customisation */
  /* Needs to be global because the component adds a modal on the root level */
  .ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
    display: none;
  }

  .ant-time-picker-panel,
  .ant-time-picker-panel-inner {
    width: 100%;
    max-width: calc(#{$date-picker-modal-width} - 60px);
  }

  .ant-time-picker-panel-select {
    width: 50% !important;
  }

  .ant-time-picker-large .ant-time-picker-input {
    cursor: pointer;
  }
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &Hidden {
    display: none;
  }
}

.view {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  max-width: $extra-large-breakpoint;
  padding: 0;
  margin: 0 auto;

  &__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    padding: $main-padding-v $main-padding-h;

    &--hFlush {
      padding-left: 0;
      padding-right: 0;
    }

    &--vFlush {
      padding-bottom: 0;
      padding-top: 0;
    }

    @include tablet-mini {
      padding: $main-padding-v-small-break $main-padding-h-small-break;

      &--hFlush {
        padding-left: 0;
        padding-right: 0;
      }

      &--vFlush {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  :global {
    .Content {
      max-width: $large-breakpoint;
      width: 100%;
      min-height: 50vh;
      margin: 0 auto;

      .PageHeader {
        flex: 1 100%;
        margin-bottom: 30px;
      }

      .PageTitle {
        @extend .h2;
        margin: 0 0 1.5rem 0;
      }

      .PageSubTitle {
        font-size: 1.1rem;
        font-weight: normal;
      }

      .ContentBlock {
        margin: 2rem 0;
      }
    }

    .Content--narrow {
      @extend .Content;
      max-width: $small-landscape-breakpoint;
      margin: 0 auto;
      padding-bottom: 10px;
    }

    .Content--wide {
      max-width: none;
    }

    .Content--empty {
      @extend .Content;
      height: 90vh;
    }

    .Content--short {
      @extend .Content;
      height: auto;
      min-height: 0;
      justify-content: center;
      display: flex;
      margin: 15px auto 0;
    }

    .Content--chatbot {
      @extend .Content--narrow;
      min-height: 235px;
    }

    .Content--form {
      @extend .Content;
      max-width: $form-breakpoint;
    }

    .small-text {
      font-size: 14px;
    }

    .centered {
      text-align: center;
    }

    .error-feedback {
      font-size: 0.8rem;
      color: $error;
      display: block;
      margin: 5px 0 20px 0;
    }
  }
}

/* Component library overwite */

:global {
  button.babylon-component-library__Button__button {
    flex: 1 0 auto;

    @include tablet-mini {
      width: auto;
      flex: 1 0 auto;
    }
  }

  .MultiSelectInput-module__goToBottomBtnVisible___6KsGT {
    display: none;
  }

  .babylon-component-library__Radiogroup__radio__label:after {
    transform: none !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  input.control:focus,
  select.control:focus,
  textarea.babylon-component-library__Textarea__textarea:focus {
    border-color: $grey-dark;
  }

  form {
    ::-webkit-scrollbar {
      width: 0px;
    }

    ::-webkit-scrollbar-track-piece {
      background-color: transparent;
      -webkit-border-radius: 6px;
    }
  }

  button.link-style {
    height: 48px;
    background-color: transparent;
    color: $primary;
    border: none;
    cursor: pointer;
  }

  a.btn-style {
    height: 48px;
    background-color: $primary;
    border: 1px solid #1fb6b6;
    color: white;
    text-decoration: none;
    padding: 0 15px;
    border-radius: 8px;
    line-height: 48px;
    cursor: pointer;
    display: block;
  }

  .link {
    @extend %link-style;
  }

  .loading {
    @include shimmerAnimation;
    * {
      color: transparent;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
