@import '/home/circleci/repo/web-app/src/overrides.scss';
.accordion {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.details {
  border-radius: $border-radius-medium;
  background-color: white;
  box-shadow: $shadow;

  &[open] .arrowIcon {
    transform: rotate(180deg);
  }
}

.arrowIcon {
  transition: transform 0.3s ease;
  transform-origin: center;
  fill: $grey90;
}

.summary {
  cursor: pointer;
  padding: $main-padding-h $main-padding-v;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::-webkit-details-marker {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-radius: $border-radius-medium;
    outline: 2px solid $blue100;
  }
}

.summaryContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.detailsContent {
  padding: 0 $main-padding-v $main-padding-h $main-padding-v;
}

.heading {
  font-weight: 500;
}
