@import '/home/circleci/repo/web-app/src/overrides.scss';
$thumbSize: 2rem;

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.wrapper {
  width: 100%;
  min-width: 350px;
  padding-bottom: $space--xl;
}

.customLabels {
  margin-bottom: $space--m;
  display: flex;
  justify-content: space-between;

  .maxLabel {
    flex-grow: 1;
    text-align: right;
  }
}

.sliderContainer {
  position: relative;
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: $thumbSize/2;
  border-radius: $border-radius-medium;
  background: transparent;
  position: relative;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: $thumbSize;
    height: $thumbSize;
    border-radius: 50%;
    background: black;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: $thumbSize;
    height: $thumbSize;
    border-radius: 50%;
    background: black;
    cursor: pointer;
  }
}

.sliderMarkers {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - $thumbSize);
  bottom: -$space--l;
  left: $thumbSize/2;
}

.sliderMarker {
  position: absolute;
  cursor: pointer;
  transform: translateX(-50%);

  &::before {
    content: '|';
    position: absolute;
    top: -$space--l;
    color: $grey-light;
    left: 50%;
    transform: translateX(-50%);
  }
}

.track,
.selected {
  position: absolute;
  height: $thumbSize/2;
  border-radius: $border-radius-medium;
  top: 1px;
}

.track {
  width: 100%;
  background: $color-divider;
}

.selected {
  background: $accentColor;
  z-index: 1;
}

.activeMarker {
  font-weight: 700;

  &::before {
    color: $accentColor;
  }
}

.focusedThumb {
  &::-webkit-slider-thumb {
    outline: 2px solid $focus-border;
    outline-offset: 2px;
  }

  &::-moz-range-thumb {
    outline: 2px solid $focus-border;
    outline-offset: 2px;
  }
}

.errorMessage {
  color: rgba(255, 0, 0, 0.7);
}
