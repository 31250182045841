@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.item {
  position: relative;
  padding: 20px 0;

  @include tablet-mini {
    padding: 20px;
  }

  @include less-than-tablet() {
    padding-left: 65px;
    padding-right: 24px;
    position: relative;
  }

  &__decoration {
    display: none;
  }

  &__row {
    display: block;
    width: 100%;
    align-items: center;
    background: transparent;
    text-align: left;
    padding: 0;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__imageWrap {
    position: absolute;
    left: 0;
    top: 20;

    @include tablet {
      position: static;
    }
  }

  &__message {
    padding-top: 10px;
    padding-bottom: 10px;

    @include tablet-mini {
      flex-grow: 1;
      padding-left: 20px;
    }

    @include less-than-tablet() {
      padding-top: 0;
    }

    &--loading {
      width: 100%;
      height: 50px;

      @include shimmerAnimation;
    }
  }
}
