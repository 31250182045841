@import '/home/circleci/repo/web-app/src/overrides.scss';
.containerV1 {
  ul {
    margin-top: 1rem;
    margin-left: 1.5rem;
  }

  ul > li {
    font-size: 1.1rem;
    font-weight: 400;
    padding-top: 8px;
    list-style-type: disc;
  }
}

.spacerV1 {
  margin-bottom: 1rem;
}

.footerSpacerV1 {
  margin-top: 2rem;
}

.subTextV1 {
  display: block;
  margin-top: 0.25rem;
}
