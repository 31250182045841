@import '/home/circleci/repo/web-app/src/overrides.scss';
@import '~@babylon/medkit/src/Link/link.module.scss';

$medkitTextColor: var(--medkit-button-primary-text-color);
$medkitPrimaryBackgroundColor: var(
  --medkit-button-primary-background-color,
  $transparent-grey
);

$medkitTextSecondaryFontSize: var(
  --medkit-text-secondary-text-font-size,
  $secondary-font-size
);

.card {
  width: 100%;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: $border-radius-medium;
  text-align: left;
  pointer-events: none;
  display: flex;

  &__button {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    border-radius: inherit;
    outline: none;
    text-align: left;
    cursor: pointer;
    pointer-events: all;
    padding: $space--m;
    background-color: $medkitPrimaryBackgroundColor;

    &__left {
      margin-left: rem-calc(8);
      margin-right: rem-calc(24);
    }

    &__right {
      display: flex;
      flex-direction: column;

      &__title {
        font-weight: 600;
        color: $medkitTextColor;
      }

      &__description {
        color: $medkitTextColor;
        font-size: $medkitTextSecondaryFontSize;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: $space--l;
  }
}
