@import '/home/circleci/repo/web-app/src/overrides.scss';
.title {
  font-size: 23px;
  font-weight: 500;
  line-height: 2rem;
}

.body {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.avatar {
  margin: 0 auto;
  width: 6rem;
  height: 6rem;

  span {
    box-shadow: 2px 4px 8px $mediumgray;
  }
}

.wrapper {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-image: url('../../../../../../assets/images/chart.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
  }
}

.metricWrapper {
  @extend .wrapper;

  &::before {
    width: 81%;
    right: 4.5%;
    top: 6%;
  }
}

.imperialWrapper {
  @extend .wrapper;

  &::before {
    width: 78%;
    right: 5%;
    top: 7%;
  }
}
