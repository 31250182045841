@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

$userMenuZIndex: 100;

.header {
  position: relative;
  padding: 2px 16px 2px 0;
  background-color: $white100;
  border-bottom: 1px solid $grey-light;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.08),
    0 0 1px 1px rgba(0, 0, 0, 0.04);

  &__wrapper {
    display: block;
    position: relative;
    max-width: $extra-large-breakpoint;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  &--simplified {
    .header__wrapper {
      display: flex;
      align-items: center;
      padding-right: 0;

      svg {
        max-width: 100%;
      }
    }
  }

  &__partition {
    height: 100%;
    padding: 15px 0;
    border-left: 1px solid $grey-light;
    margin: 0 20px;
  }

  &__logo {
    vertical-align: middle;

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__image {
      max-height: 26px;
      max-width: 124px;
    }

    a {
      display: flex;
      padding: 10px 0;
      min-height: 60px;

      .babylon-logo {
        max-height: 26px;
        max-width: 124px;
      }
    }

    &--right {
      padding: 10px 0;
      margin-left: auto;
    }
  }

  &__account {
    display: inline-block;
    position: absolute;
    padding: 10px 12px 10px 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: inherit;
    border: 0;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 3.5px 0;
      border-color: $grey-dark transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 0;
    }
  }

  &__help {
    display: inline-block;
    position: absolute;
    padding: 13px 10px 10px 0;
    top: 50%;
    right: 65px;
    transform: translateY(-50%);
    background-color: inherit;
    border: 0;
    text-align: center;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 3.5px 0;
      border-color: $grey-dark transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: -2px;
    }
  }

  &__help_item {
    padding: 0.625rem 0;
    color: #000;
  }

  &UserMenu {
    width: 300px;
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: $userMenuZIndex;
  }

  &HelpMenu {
    width: 300px;
    position: absolute;
    top: 60px;
    right: 75px;
    z-index: $userMenuZIndex;
  }
}

@media (min-width: $extra-large-breakpoint) {
  .header {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: $medium-breakpoint) {
  .header {
    &__wrapper {
      height: 100%;
      min-height: 64px;
    }

    &__logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__account {
      &::after {
        display: none;
      }
    }

    &__help {
      display: none;
    }

    &--simplified {
      .header__logo {
        position: relative;
        transform: none;
        top: auto;
        left: auto;
      }
    }
  }
}

// *** BURGER ICON ANIMATION ***
.burger {
  display: none;
  position: absolute;
  top: 10px;
  padding: 20px;
  width: 20px;
  height: 15px;
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  vertical-align: top;

  span {
    display: block;
    position: absolute;
    left: 10px;
    width: 20px;
    height: 1px;
    opacity: 1;
    background: $grey-extra-dark;
    transition: 0.25s ease-in-out;
    transform: rotate(0deg);

    &:nth-child(1) {
      top: 15px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 20px;
    }

    &:nth-child(4) {
      top: 25px;
    }
  }

  &.opened {
    span {
      &:nth-child(1) {
        top: 5px;
        left: 50%;
        width: 0%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 5px;
        left: 50%;
        width: 0%;
      }
    }
  }

  /* stylelint-disable order/order */
  @include less-than-tablet {
    display: block;
  }
  /* stylelint-enable */
}

.gp-at-hand-logo {
  max-height: 33px;
  max-width: 150px;
}
