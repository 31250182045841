@import '/home/circleci/repo/web-app/src/overrides.scss';
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.textArea {
  line-height: 1rem;
  resize: vertical;
  border: 1px solid rgba(211, 211, 211, 0.59);
  padding: 7px 16px 7px 16px;
  box-shadow: none;
  border-radius: 0.25rem;
  max-height: 40em;
  min-height: 8em;
}

.error {
  border: 1px solid $color-error;
}
