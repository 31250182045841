@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.videoCardPermissions {
  position: relative;
  display: flex;
  width: 100%;
  height: 200px;
  padding: 10px;
  margin: 20px 0;
  border-radius: 10px;
  background-color: $grey-800;

  &--error {
    background-color: $red-l10;
  }

  &__captionBox {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    bottom: 10px;
    align-self: flex-end;
    text-align: center;
    color: $white100;
    background-color: $grey-900;
  }

  &__icon {
    display: block;
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
