@import '/home/circleci/repo/web-app/src/overrides.scss';
.containerV1 {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.titleV1 {
  font-size: 23px;
  text-align: center;
  font-weight: bold;
  line-height: 2rem;
}

.subTextV1 {
  font-size: 20px;
  text-align: justify;
  font-weight: 500;
}
