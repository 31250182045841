@import '/home/circleci/repo/web-app/src/overrides.scss';
.containerV1 {
  border: 1px solid #dbdbdb;
  padding: 7px 16px;
  margin-top: 12px;
  color: #444;
  border-radius: 4px;
  min-height: 60px;
  height: fit-content;
  box-shadow: none;
  background-color: white;
  width: 100%;
  gap: 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.labelV1 {
  display: flex;
}

.containerV1:hover {
  cursor: pointer;
}

.containerV1 input {
  display: none;
}

.checkedV1 {
  border: 1px solid #000;
  box-shadow: 4px 2px 9px rgba(0, 0, 0, 0.13);
}

.radioMarkV1 {
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background-color: rgb(255, 255, 255);
  align-items: center;
  display: flex;
  margin-right: 15px;
}

.radioMarkV1::after {
  content: '';
  display: none;
}

.containerV1 input:checked ~ .radioMarkV1::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  align-self: center;
  margin: 0 auto;
  border-radius: 50%;
  background-color: $accentColor;
}

.extraLabelV1 {
  color: #aaa;
}
