@import '/home/circleci/repo/web-app/src/overrides.scss';
.loginHeader {
  height: 65px;
  padding: 10px 0;
  border-bottom: 1px solid#ddd;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.08),
    0 0 1px 1px rgba(0, 0, 0, 0.04);

  display: flex;
  justify-content: center;
  align-items: center;

  .logoContainer {
    max-width: 1200px;
    width: 100%;
    padding: 0 16px;
  }

  .logo {
    display: inline-block;
    margin: 0;

    img {
      max-height: 100%;
    }
  }
}

.eMed-logo {
  width: 76px;
}

.ask-a-and-e-logo {
  display: block;
  max-height: 40px;
}

.babylon-brazil-einstein-logo {
  display: block;
  max-height: 40px;
  max-width: 200px;
}
