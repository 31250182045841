@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 159.8 80.4% 75%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 159.8 80.4% 75%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 10%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;

    *:focus {
      outline: 2px solid hsl(240, 5.9%, 10%);
      outline-offset: 2px;
    }

    /* Update colours for Lime health */
    .theme-lime-health {
      --font-sans: 'Noto Sans, sans-serif';
      --background: 0 0% 100%;
      --foreground: 152 94% 21%;
      --card: 0 0% 100%;
      --card-foreground: 152 94% 21%;
      --popover: 0 0% 100%;
      --popover-foreground: 152 94% 21%;
      --primary: 182 100% 15%;
      --primary-foreground: 0 0% 98%;
      --secondary: 118 43% 86%;
      --secondary-foreground: 182 100% 15%;
      --muted: 240 4.8% 95.9%;
      --muted-foreground: 240 3.8% 46.1%;
      --accent: 118 52% 44%;
      --accent-foreground: 0 0% 100%;
      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 0 0% 98%;
      --border: 240 5.9% 90%;
      --input: 118 43% 86%;
      --ring: 182 100% 15%;
      --radius: 2rem;

      *:focus {
        outline: 2px solid hsl(182, 100%, 15%);
        outline-offset: 2px;
      }
    }
  }
}

@layer base {
  * {
    @apply tw-border-border;
  }

  body {
    @apply tw-bg-background tw-text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

@layer utilities {
  .step {
    counter-increment: step;
  }

  .step::before {
    @apply tw-bg-muted tw-border-background tw-absolute tw-inline-flex tw-h-9 tw-w-9 tw-items-center tw-justify-center tw-rounded-full tw-border-4 tw-text-center -tw-indent-px tw-font-mono tw-text-base tw-font-medium;
    @apply tw-ml-[-50px] tw-mt-[-4px];
    content: counter(step);
  }
}

@media (max-width: 640px) {
  .container {
    @apply tw-px-4;
  }
}
