@import '/home/circleci/repo/web-app/src/overrides.scss';
@import 'components/constants.scss';

.iframe {
  border-width: 1px;
  border-style: solid;
  border-color: $grey-light;
  height: 80vh;
  width: 100%;

  &__mobile {
    height: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: $grey-light;
    width: 100%;
  }
}

.iframeContainer {
  flex: 1;

  &__mobile {
    flex: 1;
    margin-top: -1.5rem;
    border-width: 0.5px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-color: black;
    overflow: hidden;
    width: 100vw;
    max-width: 100vw;
    margin-left: -1.25rem;
    margin-bottom: -1.25rem;
  }
}
